import React, { useEffect, useState } from 'react';
// @ts-ignore
import planeBg from './assets/planeBg.webp';
// @ts-ignore
import plane from './assets/plane.webp';
// @ts-ignore
import startGraph from './assets/start-graph.webp';

const Aviator: React.FC = () => {

    const params = new URLSearchParams(document.location.search)
  let pid = params.get('pid')
  let sub1 = params.get('sub1')
  let sub2 = params.get('sub2')

  if (pid === 'null' || pid === null || pid === '') {
    pid = '29'
  }

  if (sub1 === 'null' || sub1 === null || sub1 === '') {
    sub1 = ' '
  }
  if (sub2 === 'null' || sub2 === null || sub2 === '') {
    sub2 = ' '
  }

  const redirect = () => {
    window.location.href = `
  https://axm11.com/FDwKY5?pid=${pid}&sub1=${sub1}&sub2=${sub2}`
  }

    const [isActivated, setIsActivated] = useState<boolean>(localStorage.getItem('activated') === 'true');
    const [timerValue, setTimerValue] = useState<number>(1.0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationBtn, setAnimationBtn] = useState(true);

  
  useEffect(() => {
    setTimeout(() => {
    setAnimationBtn(prev => prev = !prev);
  }, 800);
  }, [animationBtn])
  
  useEffect(() => {
    const id = setInterval(() => {
      const btn = document.querySelector('.btn__start') as HTMLButtonElement;
      btn.classList.toggle('btn__animation');
    }, 5200);

    const refs = {
      btn: document.querySelector('.btn__start') as HTMLButtonElement,
      imgStart: document.querySelector('.img__start') as HTMLImageElement,
      count: document.querySelector('.count') as HTMLDivElement,
      background: document.querySelector('.background') as HTMLDivElement,
      timerElement: document.getElementById('timer') as HTMLDivElement,
      backdrop: document.querySelector('.backdrop__aviator') as HTMLDivElement,
      container: document.querySelector('.container') as HTMLDivElement,
      coefficient: document.querySelector('.coefficient') as HTMLDivElement,
      notification: document.querySelector('.notification') as HTMLDivElement,
      };
    
      const onStartBtnClick = () => {
        if (isAnimating) {
        localStorage.setItem('activated', 'true');
        refs.notification.classList.remove('is-hidden');
        refs.notification.classList.add('slide');
        refs.coefficient.textContent = refs.timerElement.textContent;
        setTimeout(() => {
          refs.backdrop.classList.remove('is-hidden');
          refs.container.classList.add('is-hidden');
          refs.notification.classList.add('is-hidden');
        }, 1000);
        } else {
        setIsAnimating(true);
        refs.btn.classList.add('started');
        setTimeout(() => {
          refs.notification.classList.add('is-hidden');
          refs.backdrop.classList.remove('is-hidden');
          refs.container.classList.add('is-hidden');
        }, 5200);
            clearInterval(id);
        refs.btn.style.backgroundColor = '#D97400';
        refs.btn.textContent = 'Забрати виграш';
            refs.imgStart.style.display = 'none';
            if (refs.timerElement.textContent === '1.00X') {
               animateTimer(1.0, 47.2, 5200).then(() => {
            setIsAnimating(false);
    });
            }
        refs.timerElement.classList.remove('is-hidden');
        refs.background.classList.remove('displayNone');
      }
      };
      
    refs.btn.addEventListener('click', onStartBtnClick);


 function animateTimer(startValue: number, endValue: number, duration: number) {
  const startTime = performance.now();
  const range = endValue - startValue;

  const updateTimer = () => {
    const currentTime = performance.now();
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const currentValue = startValue + range * progress;
    setTimerValue(currentValue);

    if (progress < 1) {
      requestAnimationFrame(updateTimer);
    }
  };

  requestAnimationFrame(updateTimer);

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

    return () => clearInterval(id);
  }, [isAnimating]);

  useEffect(() => {
    if (isActivated) {
      const refs = {
        backdrop: document.querySelector('.backdrop__aviator') as HTMLDivElement,
        container: document.querySelector('.container') as HTMLDivElement,
        notification: document.querySelector('.notification') as HTMLDivElement,
      };
      refs.backdrop.classList.remove('is-hidden');
      refs.container.classList.add('is-hidden');
      refs.notification.classList.add('is-hidden');
    }
  }, [isActivated]);

  useEffect(() => {
    localStorage.setItem('activated', isActivated.toString());
  }, [isActivated]);

  return (
    <div className='wrapper'>
      <div className={`container ${isActivated ? 'is-hidden' : ''}`}>
        <div className={`notification ${isActivated ? '' : 'is-hidden'}`}>
          <div>
            <p className='notification__text'>Ви виграли!</p>
            <p className='coefficient'>{timerValue.toFixed(2)}X</p>
          </div>
          <div className='notification__wrapper--right'>
            <p className='notification__text--right'>Виграш, UAH</p>
            <p className='notification__text--value'>25000₴</p>
          </div>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 25' fill='none' className='notification__icon'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.8244 12.5002L20.4144 6.91015L17.5902 4.08594L12.0002 9.67594L6.41015 4.08594L3.58594 6.91015L9.17594 12.5002L3.58594 18.0902L6.41015 20.9144L12.0002 15.3244L17.5902 20.9144L20.4144 18.0902L14.8244 12.5002ZM19 6.91L19.0002 6.91015L13.4102 12.5002L13.41 12.5L19 6.91ZM19 18.09L17.59 19.5L17.5902 19.5002L19.0002 18.0902L19 18.09ZM12.0002 13.9102L12 13.91L6.41 19.5L6.41015 19.5002L12.0002 13.9102Z'
              fill='#829177'
            />
          </svg>
        </div>
        <div className='graph__wrapper'>
          <div className={`timer count ${isActivated ? '' : 'is-hidden'}`} id='timer'>
            {timerValue.toFixed(2)}X
          </div>
          <div className='background displayNone'>
            <img src={planeBg} alt='' className='planeBg' />
          </div>
          <img src={startGraph} alt='plane graph' className='img__start' />
        </div>
        <button type='button' className={`btn__start ${isActivated ? 'started' : ''} ${animationBtn && !isAnimating ? "btn__animation" : ''}`}>
          {isActivated ? 'Забрати виграш' : 'Грати'}
        </button>
        <img src={plane} alt='background img' className='img__bottom' />
      </div>
      <div className={`backdrop__aviator ${isActivated ? '' : 'is-hidden'}`}>
        <div className='modal__aviator'>
          <div>
            <h1 className='modal__title'>Вітаємо Ви виграли</h1>
            <p className='modal__pretitle'>Бонус до</p>
          </div>
          <p className='modal__text'>25000 грн</p>
          <div>
            <a onClick={redirect} className='btn__start deep-link btn__accept'>
              Забрати бонус
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aviator;
