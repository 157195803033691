import React, { useEffect, useState } from "react";
// @ts-ignore
import PrizeBox from "../assets/layout/PrizeBox.png";

const RegisterPush = () => {
  const [push, setPush] = useState<boolean>(true);
  const [lang, setLang] = useState<string>("ua");
  useEffect(() => {
    setLang(window.navigator.language);
  }, []);
  return (
    <div
      className={
        push ? "register__push__container active" : "register__push__container"
      }
    >
      <div className={push ? "register__push active" : "register__push"}>
        <img
          className="register__push__img"
          src={PrizeBox}
          alt="box with prize"
        />
        <h3 className="register__push__title">
          {lang === "ru" ? "Welcome to ClubCasino!" : "Welcome to ClubCasino!"}
        </h3>
        <p className="register__push__text">
          {lang === "ru"
            ? `To receive the bonus, click "Allow"`
            : `To receive the bonus, click "Allow"`}
        </p>
        <a
          href="#"
          className={"sp_notify_prompt register__push__btn"}
          onClick={() => {
            setPush(false);
            window.localStorage.setItem("push", "true");
          }}
        >
          ОК
        </a>
        {/*<button className="register__push__btn" ></button>*/}
      </div>
    </div>
  );
};

export default RegisterPush;
