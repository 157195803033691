// multiplierImages
// @ts-ignore
import multiplier0dot5Img from '../../assets/multipliers/0.5.svg'
// @ts-ignore
import multiplier1dot5Img from '../../assets/multipliers/1.5.svg'
// @ts-ignore
import multiplier15Img from '../../assets/multipliers/15.svg'
// @ts-ignore
import multiplier1dot6Img from '../../assets/multipliers/68.svg'
// @ts-ignore
import multiplier10Img from '../../assets/multipliers/multiplier10.png'
// @ts-ignore
import multiplier110Img from '../../assets/multipliers/multiplier110.png'
// @ts-ignore
import multiplier18Img from '../../assets/multipliers/multiplier18.png'
// @ts-ignore
import multiplier2Img from '../../assets/multipliers/multiplier2.png'
// @ts-ignore
import multiplier25Img from '../../assets/multipliers/multiplier25.png'
// @ts-ignore
import multiplier33Img from '../../assets/multipliers/multiplier33.png'
// @ts-ignore
import multiplier41Img from '../../assets/multipliers/multiplier41.png'
// @ts-ignore
import multiplier5Img from '../../assets/multipliers/multiplier5.png'
// @ts-ignore
import multiplier88Img from '../../assets/multipliers/multiplier88.png'
// @ts-ignore
import multiplierWIN from '../../assets/multipliers/win.svg'

import { LinesType, MultiplierType } from '../@types'

const multipliers = {
  110: {
    label: 'block-110',
    img: multiplier110Img
  },
  88: {
    label: 'block-88',
    img: multiplier88Img
  },
  41: {
    label: 'block-41',
    img: multiplier41Img
  },
  33: {
    label: 'block-33',
    img: multiplier33Img
  },
  25: {
    label: 'block-25',
    img: multiplier25Img
  },
  18: {
    label: 'block-18',
    img: multiplier18Img
  },
  15: {
    label: 'block-15',
    img: multiplier15Img
  },
  10: {
    label: 'block-10',
    img: multiplier10Img
  },
  5: {
    label: 'block-5',
    img: multiplier5Img
  },
  3: {
    label: 'block-3',
    img: multiplier15Img
  },
  2: {
    label: 'block-2',
    img: multiplier2Img
  },
  1.5: {
    label: 'block-1.5',
    img: multiplier1dot6Img
  },
  1: {
    label: 'block-1',
    img: multiplier15Img
  },
  0.5: {
    label: 'block-0.5',
    img: multiplier1dot5Img
  },
  0.3: {
    label: 'block-0.3',
    img: multiplier0dot5Img
  },
  WIN: {
    label: 'block-WIN',
    img: multiplierWIN
  }
} as const

export type MultipliersType = keyof typeof multipliers

export function getMultiplier(value: MultipliersType): MultiplierType {
  return multipliers[value]
}
export const multiplyBlocks8Lines = [
  getMultiplier('WIN'),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier('WIN')
]

export const multiplyBlocksByLinesQnt = {
  8: multiplyBlocks8Lines
}

export function getMultiplierByLinesQnt(value: LinesType): MultiplierType[] {
  return multiplyBlocksByLinesQnt[value]
}
