import React, { FC, useEffect, useState } from "react";
import CardOffer from "../Components/CardOffer";
import IOffers from "../interface/IOffers";
import CardTelegram from "../Components/CardTelegram";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

const General: FC<IOffers> = ({ state, updateOffers }) => {
  const { t, i18n } = useTranslation();

  const { user } = useAuth();
  const [sub1, setSub1] = useState(
    user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino"
  );
  useEffect(() => {
    setSub1(user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino");
  }, [user]);
  return (
    <>
      <div className="title__bg">
        <p
          className={
            i18n.language === "br" || i18n.language === "pt"
              ? "title__rozdil title__rozdil__br"
              : i18n.language === "cz" ||
                i18n.language === "dk" ||
                i18n.language === "gr" ||
                i18n.language === "hu"
              ? "title__rozdil title__rozdil__cz"
              : i18n.language === "de" || i18n.language === "sk"
              ? "title__rozdil title__rozdil__de"
              : i18n.language === "it" || i18n.language === "lv"
              ? "title__rozdil title__rozdil__it"
              : i18n.language === "es" || i18n.language === "fr"
              ? "title__rozdil title__rozdil__es"
              : i18n.language === "ro" || i18n.language === "nl"
              ? "title__rozdil title__rozdil__ro"
              : "title__rozdil"
          }
        >
          {t("title")}
        </p>
      </div>
      <div className="card__container">
        {state?.map((item: any, index: any) => (
          <CardOffer
            key={index + item.title + item.id}
            item={item}
            updateOffers={updateOffers}
          />
        ))}
        {/* {sub1 === "brat" && <CardTelegram />}
        {sub1 === "alex" && <CardTelegram />}
        {sub1 === "tts" && <CardTelegram />}
        {sub1 === "tti" && <CardTelegram />}
        {sub1 === "mik" && <CardTelegram />}
        {sub1 === "moroz" && <CardTelegram />}
        {sub1 === "klon" && <CardTelegram />} */}
      </div>
    </>
  );
};

export default General;
