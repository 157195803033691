import React from 'react';
// @ts-ignore
import email from "../../assets/thank/email.png";

const ThankPageFb = () => {
    return (
        <div className="thankPage">
            <div className="thankPage__container">
                <p className="thankPage__title">Лист відправлено Вам на пошту</p>
                <img className="thankPage__img" src={email} alt="конверт з письмом "/>
                <p className="thankPage__text">
                    Ми надіслали Вам повідомлення для завершення реєстрації.
                </p>
                <p className="thankPage__text">Якщо Ви не отримали повідомлення для активації протягом 2 хвилин:</p>
                <ul className="thankPage__list__container">
                    <li className="thankPage__list">Перевірте, будь ласка, папки зі <span
                        className="thankPage__list__span">спамом</span> чи <span
                        className="thankPage__list__span">рекламою</span> .
                    </li>
                    <li className="thankPage__list">
                        Перевірте, чи ви ввели свою адресу правильно.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ThankPageFb;