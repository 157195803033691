import React, {useEffect, useState} from 'react';
import {useQueryParams} from "../Components/QueryParams";
import api from "../api";
import useAuth from "../hooks/useAuth";
import {Link} from "react-router-dom";

enum VerificationStates {
    Pending = "PENDING",
    Success = "SUCCESS",
    Error = "ERROR",
}

const EmailVerificationContainer = () => {
    const {et} = useQueryParams();
    const [verification, setVerification] = useState<VerificationStates>(VerificationStates.Pending);
    const {checkAuth} = useAuth();

    useEffect(() => {
        if (!et) return;

        api.users.verifyEmail(et)
            .then(async () => {
                setVerification(VerificationStates.Success);
                await checkAuth()
            })
            .catch((e) => setVerification(VerificationStates.Error))

    }, [et]);


    //TODO: make layout for all states (create components for them)
    return (
        <div>
            {verification === VerificationStates.Pending && <span>loading...</span>}
            {verification === VerificationStates.Success &&
                <div className="offer__none__container">
                    <p className="offer__none__title">Успіх!</p>
                    <Link className="offer__none__link" to="/">На головну</Link>
                </div>
                }
            {verification === VerificationStates.Error &&
                <div className="offer__none__container">
                    <p className="offer__none__title">От дідько !</p>
                    <p className="offer__none__text">Електронна адреса не підтверджена</p>
                </div>
            }
        </div>
    );
};

export default EmailVerificationContainer;