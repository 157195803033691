import { colors } from '../../styles/colors'
const pins = {
  startPins: 3,
  pinSize: 6,
  pinGap: 32
}

const ball = {
  ballSize: 9
}

const engine = {
  engineGravity: 1
}

const world = {
  width: 320,
  height: 320
}

export const config = {
  pins,
  ball,
  engine,
  world,
  colors
}
