import axios from "./axios";
import users from "./users";

const api = {
    users,
    async getAll() {

        const {data} = await axios.get("/offers/all/4488");

        return data;
    },

    async getDefaults() {
        const {data} = await axios.get(`/offers/defaults/7`);

        return data;
    },

    async likeOffer(offerId: number) {
        try {
            await axios.put(`/offers/like/${offerId}`);
            return true
        } catch (e) {
            return false
        }
    },

    async unlikeOffer(offerId: number) {
        try {
            await axios.delete(`/offers/like/${offerId}`);
            return true
        } catch (e) {
            return false
        }
    },

};

export default api;

