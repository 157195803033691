import React, { useEffect, useState } from "react";
// @ts-ignore
import logoTicker from "./assets/bgTicker.jpg";
// @ts-ignore
import Check from "../assets/offers/check-icon.png";
// @ts-ignore
import logo from "./assets/logo.png";
// @ts-ignore
import clubCasino from "../assets/header/ClubCasinoLogo.png";

const SuperGra = () => {
  const [linkToOpen, setLinkToOpen] = useState("");

  const urlParams = new URLSearchParams(window.location.search);

  const param = new URLSearchParams(document.location.search);

  const params = Object.fromEntries(urlParams.entries());

  let pid = param.get("pid");
  const sub1 = param.get("sub1");

  if (pid === "null" || pid === "" || pid === null || pid === undefined) {
    pid = "29";
  }

  const str = [];
  for (const p in params) {
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  }

  const link =
    `https://axioma.monster/api/ext/6509982add992e851409188b/6509983add992e85140919d9/start?pid=${pid}&sub2=mailmass` +
    (sub1 !== null ? `&sub1=${sub1}` : "");

  const fallbackLink =
    `https://axm11.com/yYvsNQ?pid=${pid}&offer_id=712&sub2=mailmass` +
    (sub1 !== null ? `&sub1=${sub1}` : ""); // put you default link here

  useEffect(() => {
    fetch(link)
      .then((r) => r.json())
      .then(({ _id }) => {
        setLinkToOpen(`https://t.me/super_gra_top_bot?start=f-${_id}`);
      })
      .catch(() => {
        setLinkToOpen(fallbackLink);
      });
  }, []);

  const addLinkToButton = () => {
    const button = document.getElementById("6509983add992e85140919d9");
    if (!button) return;

    button.onclick = () => {
      window.location.href = linkToOpen;
      setTimeout(() => {
        window.location.href = fallbackLink;
      }, 700);
    };
  };

  addLinkToButton();

  return (
    <div className="layout">
      <div className="layout__svg"></div>
      <div className={"header"}>
        <div className="header__wrapper" style={{ justifyContent: "center" }}>
          <img
            className="header__logo"
            src={clubCasino}
            alt="logotype ClubCasino"
          />
          <div className="header__title__container">
            <h1 className="header__title header__title__yellow">CLUB</h1>
            <h1 className="header__title">CASINO</h1>
          </div>
        </div>
      </div>
      <div style={{ flex: "1 1 auto" }}>
        <div className={"card__wrapper"} style={{ margin: "150px auto 80px" }}>
          <div
            className="b-marquee b-marquee--rtl"
            style={{
              background: "linear-gradient(90deg, #F01A83 0%, #FC6767 100%);",
            }}
          >
            <div className="b-marquee__text">
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
              <span style={{ margin: "0 10px" }}>БЕЗ ВІДІГРАШУ</span>
            </div>
          </div>
          <div className="card__offer" style={{ minHeight: "auto" }}>
            <div
              className={`card__top`}
              style={{
                background: `url("${logoTicker}") no-repeat`,
                backgroundSize: "cover",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <div
                  className="offer_tag"
                  style={{ background: "rgba(52, 29, 143, 0.30)" }}
                >
                  Вітальний бонус
                </div>
                <div
                  className="offer_tag"
                  style={{ background: "rgba(52, 29, 143, 0.30)" }}
                >
                  FS
                </div>
              </div>
            </div>
            <div className="card__middle">
              <div className="card__img__title__container">
                <img className="card__img" src={logo} alt="logotype casino" />
                <p className="card__title">Super Gra</p>
                <img
                  className="card__check__icon"
                  src={Check}
                  alt="check-icon"
                />
              </div>
              <div className="card__text__container">
                <p className="card__text__bonus">100 ФРІСПІНІВ</p>
                <p className="card__text__bezvidigrashu">БЕЗ ВІДІГРАШУ</p>
                {/* <p className="card__text__terms">за депозит від 500грн</p> */}
              </div>
              <div className="card__offer__nopromo">
                <p className="card__offer__nopromo__text">
                  Промокод не потрібен
                </p>
              </div>
              <div
                className="card__btn__container"
                style={{ marginBottom: "20px" }}
              >
                <button className="card__btn" id="6509983add992e85140919d9">
                  Вперед на сайт
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer__sub__container">
          <h3 className="footer__sub__title__number">18+</h3>
          <p className="footer__text__copyright">
            COPYRIGHT © 2023 CLUB CASINO
          </p>
        </div>
        <div className="footer__sub__container footer__sub__vidmova">
          <h3 className="footer__sub__title">ВІДМОВА ВІД ВІДПОВІДАЛЬНОСТІ</h3>
          <p className="footer__text">
            Ви повинні переконуватись, що дотримуєтесь усіх вікових та інших
            нормативних вимог перед тим як увійти у казино або поставити ставку.
            інформація на цьому сайті представлена тільки для гравців у казино.
          </p>
        </div>
        <div className="footer__sub__container footer__sub__gra ">
          <h3 className="footer__sub__title">ВІДПОВІДАЛЬНА ГРА</h3>
          <p className="footer__text">
            Сайт Club Casino не проводить ігри на гроші і не несе
            відповідальності ні за які втрати від гри в казино. Ми завжди
            закликаємо до використання відповідної азартної гри.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuperGra;
