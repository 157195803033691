import axios from "./axios";

export interface RegisterData {
    name: string,
    email: string,
    password: string,
}

export interface LoginData {
    email: string,
    password: string,
}

export interface GoogleData {
    email: string,
    name: string,
    googleId: string,
}

const users = {
    async register(registerData: RegisterData) {
        const {data} = await axios.post('/users/register', {
            ...registerData,
            sub1: window.localStorage.getItem('sub1'),
            sub2: window.localStorage.getItem('sub2'),
            pid: window.localStorage.getItem('pid'),
            ref_id: window.localStorage.getItem('ref_id'),
        });

        return data
    },

    async login(loginData: LoginData) {
        const {data} = await axios.post('/users/login', loginData);

        return data
    },

    async loginResend() {
        const {data} = await axios.get('/users/confirm-email', );

        return data
    },

    async loginViaGoogle(googleData: GoogleData) {
        const {data} = await axios.post('/users/auth/google', {
            ...googleData,
            sub1: window.localStorage.getItem('sub1'),
            sub2: window.localStorage.getItem('sub2'),
            pid: window.localStorage.getItem('pid'),
            clickId: window.localStorage.getItem('clickid'),
            ref_id: window.localStorage.getItem('ref_id'),
        });

        return data
    },

    async getMe() {
        const {data} = await axios.get('/users/me');

        return data
    },

    async resetPassword(email: string) {
        const {data} = await axios.post('/users/reset-password', {
            email,
        });

        return data
    },

    async restorePassword({resetPasswordToken, newPassword}: {resetPasswordToken: string, newPassword: string}) {
        const {data} = await axios.post('/users/restore-password', {
            resetPasswordToken,
            newPassword,
        });

        return data
    },

    async verifyEmail(emailToken: string) {
        const {data} = await axios.post('/users/verify-email', {
            emailToken,
        });

        return data
    },

}
export default users