import React, {FC, useState} from 'react';
import NotifyError from "../Components/Notify/NotifyError";


const ResetPassword: FC<{
    onReset: (email: string) => void;
}> = ({onReset}) => {
    const [email, setEmail] = useState<string>("");
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const sendMail = () => {
            if (!re.test(String(email).toLowerCase())) {
                NotifyError("Неправильний Email");
        } else {
                onReset(email);
            }
    }

    return (
        <div className="reset__mail">
            <p className="reset__mail__subtitle">Відновлення доступу</p>
            <p className="reset__mail__text">Ми надішлемо вам лист </p>
            <p className="reset__mail__text">з інструкцією по зміні паролю</p>
            <div className="reset__mail__input__container">
                <label className="reset__mail__label" htmlFor=""> Пошта</label>
                <span className="reset__input__email__icon"/>
                <input onChange={(e) => setEmail(e.target.value)} type="email" value={email}
                       placeholder="введіть свій email" className="reset__mail__input"/>
            </div>
            <div className="reset__mail__btn__container">
                <button className="reset__mail__btn" onClick={() => sendMail()}>Продовжити
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;