import {
  Bodies,
  Body,
  Composite,
  Engine,
  Events,
  IEventCollision,
  Render,
  Runner,
  World
} from 'matter-js'
import { useCallback, useEffect, useState } from 'react'
import { useGameStore } from '../store/game'
import { random } from '../utils/random'

// @ts-ignore
import logo from '../assets/logo.png'
import { LinesType, MultiplierValues } from './@types'
import { PlinkoGameBody } from './components/GameBody'
import { config } from './config'
import { getMultiplierByLinesQnt } from './config/multipliers'

export function Game() {
  const engine = Engine.create()
  const lines: LinesType = 8
  const [ballsInGame, setBallsInGame] = useState(10)
  const [won, setWon] = useState(false)
  const alreadyPlayed = localStorage.getItem('is-user-won-at-plinko'); 

  useEffect(() => {
    if (alreadyPlayed) {
      setBallsInGame(0);
      setWon(true);
    }
  }, [alreadyPlayed])

  const params = new URLSearchParams(document.location.search)
  let pid = params.get('pid')
  let sub1 = params.get('sub1')
  let sub2 = params.get('sub2')

  if (pid === 'null' || pid === null || pid === '') {
    pid = '29'
  }

  if (sub1 === 'null' || sub1 === null || sub1 === '') {
    sub1 = ' '
  }
  if (sub2 === 'null' || sub2 === null || sub2 === '') {
    sub2 = ' '
  }

  const redirect = () => {
    window.location.href = `
  https://axm11.com/P7WKhB?pid=${pid}&sub1=${sub1}&sub2=${sub2}`
  }

  const inGameBallsCount = useGameStore(state => state.gamesRunning)
  const incrementInGameBallsCount = useGameStore(
    state => state.incrementGamesRunning
  )
  const decrementInGameBallsCount = useGameStore(
    state => state.decrementGamesRunning
  )
  const {
    pins: pinsConfig,
    colors,
    ball: ballConfig,
    engine: engineConfig,
    world: worldConfig
  } = config

  const worldWidth: number = worldConfig.width

  const worldHeight: number = worldConfig.height

  useEffect(() => {
    engine.gravity.y = engineConfig.engineGravity
    const element = document.getElementById('plinko')
    const render = Render.create({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      element: element!,
      bounds: {
        max: {
          y: worldHeight,
          x: worldWidth
        },
        min: {
          y: 0,
          x: 0
        }
      },
      options: {
        background: colors.background,
        hasBounds: true,
        width: worldWidth,
        height: worldHeight,
        wireframes: false
      },
      engine
    })
    const runner = Runner.create()
    Runner.run(runner, engine)
    Render.run(render)
    return () => {
      World.clear(engine.world, true)
      Engine.clear(engine)
      render.canvas.remove()
      render.textures = {}
    }
  }, [lines])

  const pins: Body[] = []

  for (let l = 0; l < lines; l++) {
    const linePins = pinsConfig.startPins + l
    const lineWidth = linePins * pinsConfig.pinGap
    for (let i = 0; i < linePins; i++) {
      const pinX =
        worldWidth / 2 -
        lineWidth / 2 +
        i * pinsConfig.pinGap +
        pinsConfig.pinGap / 2

      const pinY =
        worldWidth / lines + l * pinsConfig.pinGap + pinsConfig.pinGap - 40

      const pin = Bodies.circle(pinX, pinY, pinsConfig.pinSize, {
        label: `pin-${i}`,
        render: {
          fillStyle: '#F5DCFF'
        },
        isStatic: true
      })
      pins.push(pin)
    }
  }

  function addInGameBall() {
    if (inGameBallsCount > 9) return
    incrementInGameBallsCount()
  }

  function removeInGameBall() {
    decrementInGameBallsCount()
  }

  const addBall = useCallback(
    (ballValue: number) => {
      addInGameBall()

      const minBallX =
        worldWidth / 2 - pinsConfig.pinSize * 3 + pinsConfig.pinGap
      const maxBallX =
        worldWidth / 2 -
        pinsConfig.pinSize * 3 -
        pinsConfig.pinGap +
        pinsConfig.pinGap / 2

      let ballX = random(minBallX, maxBallX) + 5

      const randomize = random(0, 1)

      if (randomize >= 0.75) {
        ballX = 151
      }

      const ballColor = ballValue <= 0 ? colors.text : colors.purple

      // spawnpoint
      const ball = Bodies.circle(ballX, 5, ballConfig.ballSize, {
        restitution: 1,
        friction: 0.6,
        label: `ball-${ballValue}`,
        id: new Date().getTime(),
        frictionAir: 0.05,
        collisionFilter: {
          group: -1
        },
        render: {
          fillStyle: ballColor
        },
        isStatic: false
      })
      Composite.add(engine.world, ball)
    },
    [lines]
  )

  const leftWall = Bodies.rectangle(
    worldWidth / 4 - pinsConfig.pinSize * pinsConfig.pinGap - pinsConfig.pinGap,
    worldWidth / 17 - pinsConfig.pinSize,
    worldWidth * 2,
    20,
    {
      angle: 90,
      render: {
        visible: false
      },
      isStatic: true
    }
  )
  const rightWall = Bodies.rectangle(
    worldWidth / 4 - pinsConfig.pinSize * pinsConfig.pinGap - pinsConfig.pinGap,
    worldWidth / 17 - pinsConfig.pinSize,
    worldWidth * 2,
    20,
    {
      angle: -90,
      render: {
        visible: false
      },
      isStatic: true
    }
  )
  const floor = Bodies.rectangle(0, worldWidth + 10, worldWidth * 10, 40, {
    label: 'block-1',
    render: {
      visible: false
    },
    isStatic: true
  })

  const multipliers = getMultiplierByLinesQnt(lines)

  const multipliersBodies: Body[] = []

  let lastMultiplierX: number =
    worldWidth / 2 - (pinsConfig.pinGap / 2) * lines - pinsConfig.pinGap - 7

  multipliers.forEach(multiplier => {
    const blockSize = 35
    const multiplierBody = Bodies.rectangle(
      lastMultiplierX + 33.4,
      worldWidth / lines + lines * pinsConfig.pinGap + pinsConfig.pinGap - 50,
      blockSize,
      blockSize,
      {
        label: multiplier.label,
        isStatic: true,
        render: {
          sprite: {
            xScale: 1,
            yScale: 1,
            texture: multiplier.img
          }
        }
      }
    )
    lastMultiplierX = multiplierBody.position.x
    multipliersBodies.push(multiplierBody)
  })

  Composite.add(engine.world, [
    ...pins,
    ...multipliersBodies,
    leftWall,
    rightWall,
    floor
  ])

  function bet(betValue: number) {
    if (ballsInGame === 0) {
      return
    }

    addBall(betValue)
    setBallsInGame(prevstate => (prevstate -= 1))
  }
  async function onCollideWithMultiplier(ball: Body, multiplier: Body) {
    ball.collisionFilter.group = 2
    World.remove(engine.world, ball)
    removeInGameBall()
    const multiplierValue = multiplier.label.split('-')[1] as MultiplierValues

    if (multiplierValue === 'WIN') {
      setWon(true)
      localStorage.setItem('is-user-won-at-plinko', "true");
    }
  }
  async function onBodyCollision(event: IEventCollision<Engine>) {
    const pairs = event.pairs
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair
      if (bodyB.label.includes('ball') && bodyA.label.includes('block'))
        await onCollideWithMultiplier(bodyB, bodyA)
    }
  }

  Events.on(engine, 'collisionActive', onBodyCollision)

  return (
    <div
      className="flex h-fit flex-col-reverse items-center justify-center gap-4 md:flex-row"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#222A5D', height: "100vh" }}
    >
      <img src={logo} alt="logo" />
      <p className="textTitle">кількість кидків: {ballsInGame}</p>
      <p
        style={{
          fontSize: '22px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#FFFFFF'
        }}
      >
        Виграй супер бонус
      </p>
      <div
        className="flex flex-1 items-center justify-center"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
      <div className="game-body-container">
        <PlinkoGameBody />
      </div>
        <button
          type="button"
          onClick={() => {
            bet(1)
          }}
          style={{
            backgroundColor: '#E12C2C',
            maxWidth: '374px',
            width: '90%',
            margin: '0 auto',
            height: '72px',
            borderRadius: '17px',
            color: '#FFFFFF',
            fontSize: '32px',
            fontWeight: 800,
            lineHeight: '36px',
            letterSpacing: '0em',
            textAlign: 'center',
            border: 'transparent'
          }}
        >
          Грати
        </button>
      </div>
      {won && (
        <div className="backdrop">
          <div className="modal">
            <p className="mainText">ВІТАЄМО !</p>
            <p className="text">ви виграли:</p>
            <p className="yellowText">100 ФРІСПІНІВ</p>
            <div className="middleText__bg">
              <p className="middleText">БЕЗ ВІДІГРАШУ</p>
            </div>
            <a className="button" target="_blank" onClick={redirect}>
              Забрати бонус
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
