import React, {FC, Fragment} from 'react';
import LogoutIcon from "../Components/icons/LogoutIcon";
// @ts-ignore
import UserIcon from "../assets/Profile/user-profile-icon.png";
import {Link} from "react-router-dom";
import GA from "../hooks/GA";
// import useAuth from "../hooks/useAuth";
// import NotifySuccess from "../Components/Notify/NotifySuccess";
// import NotifyError from "../Components/Notify/NotifyError";

interface Iuser {
    user: {
        name: string,
        email: string,
        emailVerified: boolean
    },
    logout: () => void
}

const Profile: FC<Iuser> = ({user, logout}) => {
    const {LogoutProfile} = GA();
    // const {loginResend} = useAuth();
    // const handleLoginResend = ()=>{
    //     loginResend().then((r)=>{
    //             NotifySuccess("Повторний лист відправлено, перевірте пошту")
    //         }
    //     ).catch(e => NotifyError("Виникла помилка, спробуйте пізніше"))
    // };
    return (
        <Fragment>
            {/*<div style={{*/}
            {/*    position: 'fixed',*/}
            {/*    top: '51px',*/}
            {/*    color: 'white',*/}
            {/*    left: '0',*/}
            {/*    height: '30px',*/}
            {/*    width: '100%',*/}
            {/*    backgroundColor: 'rgba(50,4,65,0.32)',*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    zIndex: 1000*/}
            {/*}}>update</div>*/}

            <div className="profile__name__container">
                <img className="profile__name__img" src={UserIcon} alt="user profile icon"/>
                <div className="profile__name__text__container">
                    <p className="profile__name__text__subtitle">Профіль</p>
                    <p className="profile__name__text__name">{user.name}</p>
                </div>
            </div>
            <div className="profile__flex__container">

                <div className="profile__email">
                    <p className="profile__email__subtitle">Ваша пошта:</p>
                    <p className="profile__email__text">{user.email}</p>
                </div>
                {/*                    STATUS                 */}

                {/*<div className="profile__email profile__email__verify__container">*/}
                {/*    <p className="profile__email__subtitle">Ваш статус:</p>*/}
                {/*    <p className={user.emailVerified ? "profile__email__status profile__email__verify" : " profile__email__status profile__email__noverify"}>*/}
                {/*        {user.emailVerified ?*/}
                {/*        "Верифіковано"*/}
                {/*        :*/}
                {/*        "Не Верифіковано"*/}
                {/*        }*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*{!user.emailVerified &&*/}
                {/*    <div className="profile__email profile__resend__container">*/}
                {/*    <p className="profile__email__subtitle">Щоб отримати лист верифікації на пошту ще раз, натисніть на кнопку</p>*/}
                {/*    <button onClick={handleLoginResend} className="profile__resend__btn">Надіслати</button>*/}
                {/*</div>}*/}

                {/*                    STATUS                 */}

                <Link to="/" className="profile__btn" onClick={() => {
                    logout()
                    LogoutProfile()
                }}>Вийти з профілю
                    <span className="profile__btn__icon">
                    <LogoutIcon/>
                </span>
                </Link>
            </div>
            {/*<div className="profile__link__container">*/}
            {/*    <a className="profile__link" href="">Звязатись з нами</a>*/}
            {/*</div>*/}
        </Fragment>
    );
};

export default Profile;
