import React from 'react';
import {useGoogleLogin} from "react-google-login";
import useAuth from "../../hooks/useAuth";
import NotifyError from "../Notify/NotifyError";
// import GoogleLogo from "../../assets/Login.Registr/google-icon.png"
// @ts-ignore
import GoogleLogo from "../../assets/Login.Registr/google-icon.png"

const GoogleAuthBtn = ({label = 'Вхід через Google', className,disabled}: {
    label?: string,
    className?: string
    disabled?: boolean
}) => {
    const {loginViaGoogle} = useAuth();

    const responseGoogle = async (response: any) => {
        try {
            const {googleId, email, name} = response.profileObj;
            await loginViaGoogle({
                googleId,
                name, email
            });
            window.location.href = "https://clubcasino.com.ua/";
        }catch (e) {

            NotifyError("Виникла помилка! " +
                "  Можливо ви вже реєстрували цей email. Спробуйте вхід!")
        }
    }

    const error = (err: any) => {
        console.log(err)
        // NotifyError("виникла помилка(можливо ви вже реєстрували цей email)")
    }

    const {signIn} = useGoogleLogin({
        onSuccess: responseGoogle,
        onFailure: error,
        clientId: '952733203445-ttjoeqpa7095d7q3ltnn5e3hlcgjeqdh.apps.googleusercontent.com',
    })

    return (
        <button disabled={disabled} className={className} onClick={signIn}>
         <div className={`google__icon__container `}>
             {/*<span className="google__icon"/>*/}
             <img className="google__icon__img" src={GoogleLogo} alt="logo google icon"/>
         </div>
          <p className={`${className}__text`}>{label}</p>
        </button>
    );
};

export default GoogleAuthBtn;