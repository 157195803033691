import React from 'react';
// @ts-ignore
import logoTicker from "./assets/bgTicker.jpg"
// @ts-ignore
import Check from "../assets/offers/check-icon.png";
// @ts-ignore
import logo from "./assets/logo.png";
// @ts-ignore
import clubCasino from "../assets/header/ClubCasinoLogo.png";
import {NavLink} from "react-router-dom";


const Canada = () => {
    return (
        <div className="layout" style={{height: "100%"}}>
            <div className="layout__svg">
            </div>
            <div className={"header"}>
                <div className="header__wrapper" style={{justifyContent: "center"}}>
                    <img className="header__logo" src={clubCasino} alt="logotype ClubCasino"/>
                    <div className="header__title__container">
                        <h1 className="header__title header__title__yellow">CLUB</h1>
                        <h1 className="header__title">CASINO</h1>
                    </div>
                </div>
            </div>
            <div style={{flex: "1 1 auto"}}>
                <div className={"card__wrapper"} style={{margin: "150px auto"}}>
                    <div className="b-marquee b-marquee--rtl" style={{background: `#60A0EC`}}>
                        <div className="b-marquee__text">
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                            <span style={{margin: "0 10px"}}>NEW</span>
                        </div>
                    </div>
                    <div className="card__offer" style={{minHeight: "auto"}}>
                        <div className={`card__top`} style={{
                            background: `url("${logoTicker}") no-repeat`,
                            backgroundSize: 'cover'
                        }}>
                            <div style={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <div className='offer_tag'
                                     style={{background: "rgba(52, 29, 143, 0.30)"}}>
                                    Wellcome bonus
                                </div>
                                <div className='offer_tag'
                                     style={{background: "rgba(52, 29, 143, 0.30)"}}>
                                    % + FS
                                </div>
                            </div>
                        </div>
                        <div className="card__middle">
                            <div className="card__img__title__container">
                                <img className="card__img" src={logo} alt="logotype casino"/>
                                <p className="card__title">Fbet</p>
                                <img className="card__check__icon" src={Check} alt="check-icon"/>
                            </div>
                            <div className="card__text__container">
                                <p className="card__text__bonus">1000CAD + 100 FS</p>
                                {/*{item?.trigger &&*/}
                                {/*    <p className="card__text__bezvidigrashu">{item.trigger}</p>*/}
                                {/*}*/}
                                {/*<p className="card__text__terms">{item.terms}</p>*/}
                            </div>
                            <div className="card__btn__container" style={{marginBottom: "20px"}}>
                                <button
                                    className="card__btn deep-link"
                                    onClick={() => {
                                        window.location.href = "https://axioma.g2afse.com/click?pid=77&offer_id=535&sub2=mailmass"
                                    }}
                                >
                                    claim bonus
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer__sub__container">
                    <h3 className="footer__sub__title__number">18+</h3>
                    <p className="footer__text__copyright">COPYRIGHT © 2023
                        CLUB CASINO</p>
                </div>
                <div className="footer__sub__container footer__sub__vidmova">
                    <h3 className="footer__sub__title">DISCLAIMER OF LIABILITY</h3>
                    <p className="footer__text">You must ensure that you comply with all age and other regulatory
                        requirements before entering the casino or placing a bet. information on this site is presented
                        only for casino players.</p>
                </div>
                <div className="footer__sub__container footer__sub__gra ">
                    <h3 className="footer__sub__title">RESPONSIBLE GAME</h3>
                    <p className="footer__text">The Club Casino site does not play games for money and is not
                        responsible for any losses from playing in the casino. We always encourage the use of
                        appropriate gambling.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Canada;
