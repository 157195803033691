import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__sub__container">
        <h3 className="footer__sub__title__number">18+</h3>
        <p className="footer__text__copyright">COPYRIGHT © 2024 CLUBCASINO</p>
      </div>
      {/* <div className="footer__sub__container footer__sub__vidmova">
        <h3 className="footer__sub__title">DISCLAIMER OF LIABILITY</h3>
        <p className="footer__text">
          You must ensure that you meet all age and other and other regulatory
          requirements before entering the Casino or placing a bet. The
          information on this website is for casino players only.
        </p>
      </div>
      <div className="footer__sub__container footer__sub__gra ">
        <h3 className="footer__sub__title">RESPONSIBLE GAMING</h3>
        <p className="footer__text">
          The Club Casino website does not conduct real money games and is not
          responsible for any losses incurred while playing at the casino. We
          always encourage you to the use of a suitable game of chance.
        </p>
      </div> */}
      {/* <div className="footer__sub__container footer__unsubscribe__link__container">
        <NavLink exact to="/unsubscribe" className="footer__unsubscribe__link">
          Відмовитись від розсилань
        </NavLink>
      </div> */}
    </footer>
  );
};

export default Footer;
