import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL ?? "https://offers.axmclub.top";
// const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:4002";

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers.Authorization = window.localStorage.getItem('token') ?? '';

    return config
})

export default instance;