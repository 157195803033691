import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareIcons from "./icons/ShareIcons";
// @ts-ignore
import Check from "../assets/offers/check-icon.png";
import useAuth from "../hooks/useAuth";
import api from "../api";
import { useDispatch } from "react-redux";
import { addPopup } from "../store/reducers/PopupReducer";
// @ts-ignore
import like from "../assets/offers/like.png";
// @ts-ignore
import unLike from "../assets/offers/unLike.png";
import NotifySuccess from "./Notify/NotifySuccess";
import GA from "../hooks/GA";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CardOfferProps {
  item: any;
  updateOffers: any;
}

const CardOffer: FC<CardOfferProps> = ({ item, updateOffers }) => {
  const [skipTelegram, setSkipTelegram] = useState(false);
  const [fromMail, setFromMail] = useState<string | null>("false");
  const {
    isAuth,
    user,
    checkAuth,
    user: { emailVerified },
  } = useAuth();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const handleBurgerBtnAdd = () => {
    dispatch(
      addPopup(
        "лайк",
        "Увійдіть у свій профіль або реєструйтеся щоб відкрити доступ до лайк"
      )
    );
  };

  const { t, i18n } = useTranslation();

  const tags = useMemo(() => {
    const tagsArr = item?.tags?.split(",");
    return tagsArr[0] !== "" ? tagsArr : [];
  }, [item?.tags]);

  const isOfferLiked = useMemo(
    () => user?.likedOffers?.includes(item.id),
    [user]
  );

  const toggleLike = async () => {
    if (!isAuth) {
      handleBurgerBtnAdd();
      return;
    }

    if (!isOfferLiked) {
      await api.likeOffer(item.id);
    } else {
      await api.unlikeOffer(item.id);
    }

    updateOffers("showAll");

    await checkAuth();
  };
  const clickid = window.localStorage.getItem("clickid");
  const refId = window.localStorage.getItem("ref_id");
  const link = useMemo(() => {
    const sub1 = item.link.replace(
      "${sub1}",
      user?.sub1 ?? window.localStorage.getItem("sub1")
    );
    const sub2 = sub1.replace(
      "${pid}",
      user?.pid ?? window.localStorage.getItem("pid")
    );
    if (clickid && !refId) {
      return sub2
        .replace("${sub2}", window.localStorage.getItem("sub2") ?? "direct")
        .concat("&sub3=", clickid);
    } else if (clickid && refId) {
      return sub2
        .replace("${sub2}", window.localStorage.getItem("sub2") ?? "direct")
        .concat("&sub3=", clickid)
        .concat("&ref_id=", refId);
    } else if (refId) {
      return sub2
        .replace("${sub2}", window.localStorage.getItem("sub2") ?? "direct")
        .concat("&ref_id=", refId);
    }
  }, [item, user]);

  const viberLink = useMemo(() => {
    const sub1 = item.viberLink.replace(
      "${sub1}",
      user?.sub1 ?? window.localStorage.getItem("sub1")
    );
    const sub2 = sub1.replace(
      "${pid}",
      user?.pid ?? window.localStorage.getItem("pid")
    );
    return sub2.replace(
      "${sub2}",
      window.localStorage.getItem("sub2") ?? "direct"
    );
  }, [item, user]);
  const AddPopupBtn = (text: string, allText: string) => {
    dispatch(addPopup(text, allText));
  };
  /************************************
   **** GOOGLE ANALYTICS
   *************************************/
  const {
    ClickOnOfferGoogleA,
    CopyPromoAuthGoogleA,
    CopyPromoNoAuthNoRegisterGoogleA,
  } = GA();
  /************************************
   **** SHARE FUNC
   *************************************/
  const shareData = {
    title: "Clubcasino",
    text: "ClubCasino - website with the best casino bonuses🎁🎰Collect them right now😎",
    url: "https://clubcasino-uk.top/",
  };
  const shareBtn = () => {
    navigator.share(shareData).then((r) => console.log(r));
  };
  const ref = useRef(null);
  const redirect = () => {
    const sub1 = item.link.replace(
      "${sub1}",
      user?.sub1 ?? window.localStorage.getItem("sub1")
    );
    const sub2 = sub1.replace(
      "${pid}",
      user?.pid ?? window.localStorage.getItem("pid")
    );
    window.location.href = sub2.replace(
      "${sub2}",
      window.localStorage.getItem("sub2") ?? "direct"
    );
  };
  useEffect(() => {
    let dataQuery = searchParams.get("mail");
    let dataSessionStorage = sessionStorage.getItem("mail");
    if (dataQuery === "true") {
      setFromMail("true");
      sessionStorage.setItem("mail", "true");
    } else if (dataSessionStorage === "true") {
      setFromMail("true");
    } else {
      setFromMail("false");
    }
  }, []);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (searchParams.get("tg") || sessionStorage.getItem("tg")) {
      setSkipTelegram(true);
      sessionStorage.setItem("tg", "true");
    } else {
      setSkipTelegram(false);

      let pixel = window.localStorage.getItem("pixel");
      let clickHandler = (t: { preventDefault: () => any }) => {
        let e = "deep-link";
        let r: any = true;
        const subId2 = window.localStorage.getItem("sub2") ?? "direct";
        const subId3 = window.sessionStorage.getItem("sub3");
        const subId4 = window.sessionStorage.getItem("sub4");
        const subId5 = window.sessionStorage.getItem("sub5");
        const subId6 = window.sessionStorage.getItem("sub6");
        const subId7 = window.sessionStorage.getItem("sub7");
        const subId8 = window.sessionStorage.getItem("sub8");
        // let n = {
        //   variables: {
        //     pid: user?.pid ?? window.localStorage.getItem("pid"),
        //     sub3: user?.sub1 ?? window.localStorage.getItem("sub1"),
        //     sub2: subId2,
        //     ref_id: user?.ref_id ?? window.localStorage.getItem("ref_id"),
        //   },
        // };
        // if (pixel !== null) {
        //   // @ts-ignore
        //   n.variables[pixel] = pixel;
        // }
        // // if (subId3 !== null) {
        // //     // @ts-ignore
        // //     n.variables["sub3"] = subId3;
        // // }
        // if (subId4 !== null) {
        //   // @ts-ignore
        //   n.variables["sub4"] = subId4;
        // }
        // if (subId5 !== null) {
        //   // @ts-ignore
        //   n.variables["sub5"] = subId5;
        // }
        // if (subId6 !== null) {
        //   // @ts-ignore
        //   n.variables["sub6"] = subId6;
        // }
        // if (subId7 !== null) {
        //   // @ts-ignore
        //   n.variables["sub7"] = subId7;
        // }
        // if (subId8 !== null) {
        //   // @ts-ignore
        //   n.variables["sub8"] = subId8;
        // }
        // let a = {
        //   context: {
        //     range: [],
        //     scope: {},
        //     variables: { pid: "3", sub1: "2hello@smartsender.com" },
        //   },
        //   messengers: [
        //     {
        //       key: "?",
        //       name: "telegram",
        //       parameter: "start",
        //       prefixes: ["tg://", "https://t.me/"],
        //     },
        //   ],
        //   delimiter: "|",
        //   url: "https://clubuatop.customer.smartsender.eu/api/i/store",
        // };
        // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // t.preventDefault(),
        //   (function (e) {
        //     let t = new XMLHttpRequest();
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     t.open(
        //       "POST",
        //       "https://clubuatop.customer.smartsender.eu/api/i/store"
        //     ),
        //       t.setRequestHeader("Content-type", "application/json"),
        //       t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
        //       (t.onreadystatechange = function () {
        //         if (4 === this.readyState && 200 === this.status) {
        //           // @ts-ignore
        //           let t: any = JSON.parse(this.responseText);
        //           // eslint-disable-next-line no-restricted-globals
        //           location.href = (function (e, t) {
        //             let r = a.messengers.find(function (t) {
        //                 return t.prefixes.find(function (t) {
        //                   // @ts-ignore
        //                   return 0 === e.indexOf(t);
        //                 });
        //                 // @ts-ignore
        //               }),
        //               // @ts-ignore
        //               n = e.split(r.key),
        //               s = n[1].split("&");
        //             // @ts-ignore
        //             return (
        //               // @ts-ignore
        //               s.forEach(function (e, n) {
        //                 let i = e.split("=");
        //                 // @ts-ignore
        //                 if (r.parameter === i[0]) {
        //                   let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
        //                     /=/g,
        //                     ""
        //                   );
        //                   // @ts-ignore
        //                   s.splice(n, 1, [r.parameter, e].join("="));
        //                 }
        //                 // @ts-ignore
        //               }),
        //               // @ts-ignore
        //               n[0] + r.key + s.join("&")
        //             );
        //           })(e, t);
        //         }
        //       }),
        //       t.send(
        //         (function (e) {
        //           return (
        //             Object.keys(a.context).forEach(function (t) {
        //               // @ts-ignore
        //               Object.keys(e).includes(t) || (e[t] = {});
        //             }),
        //             JSON.stringify(
        //               r
        //                 ? ((t = e),
        //                   new URLSearchParams(window.location.search).forEach(
        //                     function (e, r) {
        //                       // @ts-ignore
        //                       t.variables.hasOwnProperty(r) ||
        //                         // @ts-ignore
        //                         (t.variables[r] = e);
        //                     }
        //                   ),
        //                   t)
        //                 : e
        //             )
        //           );
        //           // @ts-ignore
        //           var t;
        //         })(n)
        //       );
        //   })(viberLink);
        // setTimeout(function () {
        // const sub1 = item.link.replace('${sub1}', user?.sub1 ?? window.localStorage.getItem('sub1'));
        // const sub2 = sub1.replace('${pid}', user?.pid ?? window.localStorage.getItem('pid'));
        const offerId = item.link.match(/offer_id=([0-9]+)/)[1];
        const sub1 = user?.sub1 ?? window.localStorage.getItem("sub1");
        const pidId = user?.pid ?? window.localStorage.getItem("pid");
        const sub2 = window.localStorage.getItem("sub2") ?? "direct";
        const sub3 = window.sessionStorage.getItem("sub3");
        const sub4 = window.sessionStorage.getItem("sub4");
        const sub5 = window.sessionStorage.getItem("sub5");
        const sub6 = window.sessionStorage.getItem("sub6");
        const sub7 = window.sessionStorage.getItem("sub7");
        const sub8 = window.sessionStorage.getItem("sub8");
        window.location.href =
          `https://axioma.g2afse.com/click?pid=${pidId}&offer_id=${offerId}` +
          (sub1 !== null ? `&sub1=${sub1}` : "") +
          (sub2 !== null ? `&sub2=${sub2}` : "") +
          (sub3 !== null ? `&sub3=${sub3}` : "") +
          (pixel !== null ? `&pixel=${pixel}` : "") +
          (refId !== null ? `&ref_id=${refId}` : "") +
          (sub4 !== null ? `&sub4=${sub4}` : "") +
          (sub5 !== null ? `&sub5=${sub5}` : "") +
          (sub6 !== null ? `&sub6=${sub6}` : "") +
          (sub7 !== null ? `&sub7=${sub7}` : "") +
          (sub8 !== null ? `&sub8=${sub8}` : "");
        //https://axm11.com/yYvsNQ
        // if (clickid && !refId) {
        //     // @ts-ignore
        //     return window.location = pixel.replace('${sub2}', window.localStorage.getItem('sub2') ?? "direct").concat("&sub3=", clickid);
        // } else if (clickid && refId) {
        //     // @ts-ignore
        //     return window.location = pixel.replace('${sub2}', window.localStorage.getItem('sub2') ?? "direct").concat("&sub3=", clickid).concat("&ref_id=", refId);
        // } else if (refId) {
        //     // @ts-ignore
        //     return window.location = pixel.replace('${sub2}', window.localStorage.getItem('sub2') ?? "direct").concat("&ref_id=", refId);
        // }
        // return window.location.href = `https://axm11.com/yYvsNQ?pid=${user?.sub1 ?? window.localStorage.getItem('sub1')}&offer_id=254&sub1=${sub1}&sub2=${sub2}&pixel=${pixel}` + (sub3 !== null && `&sub3=${"test"}`) + (sub4 !== null && `&sub4=${"test"}`) + (sub5 !== null && `&sub5=${"test"}`) + (sub6 !== null && `&sub6=${"test"}`) + (sub7 !== null && `&sub7=${"test"}`) + (sub8 !== null && `&sub8=${"test"}`)
        // window.location = pixel.replace('${sub2}', window.localStorage.getItem('sub2') ?? "direct")
        // }, 2000);
      };
      // @ts-ignore
      ref?.current.addEventListener("click", clickHandler);
      // @ts-ignore
      return () => ref?.current?.removeEventListener("click", clickHandler);
    }
  }, [ref]);

  return (
    <div
      className={
        item?.ticker
          ? "card__wrapper card__wrapper__bezVidigrashu"
          : "card__wrapper"
      }
      style={{
        order: item?.order,
      }}
    >
      {item?.ticker && (
        <div
          className="b-marquee b-marquee--rtl"
          style={{ background: `${item?.tickerBackground}` }}
        >
          <div className="b-marquee__text">
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
            <span style={{ margin: "0 10px" }}>{item.ticker}</span>
          </div>
        </div>
      )}
      <div className="card__offer">
        <div
          className={`card__top`}
          style={{
            background: `url("${item?.headerImageUrl}") no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            {tags?.map((tag: string) => (
              <div
                key={item?.name + tag}
                className="offer_tag"
                style={{ background: item?.tagsBackground }}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
        <div className="card__middle">
          <div className="card__img__title__container">
            <img
              className="card__img"
              src={item?.avatarImageUrl}
              alt="logotype casino"
            />
            <p className="card__title">{item?.title}</p>
            <img className="card__check__icon" src={Check} alt="check-icon" />
          </div>
          <div className="card__text__container">
            <p className="card__text__bonus">{item?.description}</p>
            {item?.trigger && (
              <p className="card__text__bezvidigrashu">{item.trigger}</p>
            )}
            <p className="card__text__terms">{item.terms}</p>
          </div>
          <div
            className={
              i18n.language === "br"
                ? "card__offer__nopromo card__offer__nopromo__br"
                : "card__offer__nopromo"
            }
          >
            <p
              className={
                i18n.language === "br" ||
                i18n.language === "bg" ||
                i18n.language === "es" ||
                i18n.language === "pt" ||
                i18n.language === "sk"
                  ? "card__offer__nopromo__text card__offer__nopromo__text__br"
                  : i18n.language === "cz"
                  ? "card__offer__nopromo__text card__offer__nopromo__text__cz"
                  : i18n.language === "de" ||
                    i18n.language === "gr" ||
                    i18n.language === "hu" ||
                    i18n.language === "si"
                  ? "card__offer__nopromo__text card__offer__nopromo__text__de"
                  : i18n.language === "it" ||
                    i18n.language === "fr" ||
                    i18n.language === "ro"
                  ? "card__offer__nopromo__text card__offer__nopromo__text__it"
                  : "card__offer__nopromo__text"
              }
            >
              {t("noRequired")}
            </p>
          </div>
          <div className="card__btn__container ">
            <button
              ref={ref}
              className="card__btn deep-link"
              id={viberLink}
              onClick={() => {
                {
                  skipTelegram && redirect();
                }
                // LocationToRedirect(link, viberLink);
                ClickOnOfferGoogleA(item?.title);
              }}
            >
              {t("button")}
            </button>
          </div>
        </div>
        <div className="card__bottom">
          {/* <div className="card__like__container">
            <div className="card__like__img" onClick={toggleLike}>
              {isOfferLiked ? (
                <img className="card__like__img" src={like} alt="" />
              ) : (
                <img className="card__like__img" src={unLike} alt="" />
              )}
            </div>
            <p className="card__like__text" onClick={toggleLike}>
              {item?.likesCount} Подобається
            </p>
          </div>
          <div className="card__bottom__border" />
          <div onClick={shareBtn} className="card__share__container">
            <div className="card__share__img">
              <ShareIcons />
            </div>
            <p className="card__share__text">Поділитись</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardOffer;
