import React, {useState} from 'react';
import ResetPassword from "../pages/ResetPassword";
import api from "../api";

enum ResetPasswordStates {
    Pending = "PENDING",
    Success = "SUCCESS",
    Error = "ERROR",
}

const ContainerResetPassword = () => {
    const [reset, setReset] = useState<ResetPasswordStates>(ResetPasswordStates.Pending);

    const handleReset = (email: string) => {
        api.users.resetPassword(email).then(() => setReset(ResetPasswordStates.Success)).catch((e) => setReset(ResetPasswordStates.Error));
    }

    return (
        <>
            {reset === ResetPasswordStates.Pending && <ResetPassword onReset={handleReset}/>}
            {reset === ResetPasswordStates.Success &&
                <>
                    <div className="offer__none__container">
                        <p className="offer__none__text">Якщо електронна адреса правильна, ми надіслали вам інструкцію</p>
                    </div>
                </>
            }
        </>
    );
};

export default ContainerResetPassword;